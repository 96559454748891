import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';

const Login = ({ setUsername }) => {
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const handleLogin = () => {
        if (name.trim()) {
            setUsername(name.trim());
            navigate('/chat');
        } else {
            alert('请输入用户名');
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
        >
            <Typography variant="h4" gutterBottom>
                欢迎来到聊天应用
            </Typography>
            <TextField
                label="用户名"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ marginBottom: '20px', width: '300px' }}
            />
            <Button variant="contained" color="primary" onClick={handleLogin}>
                进入聊天
            </Button>
        </Box>
    );
};

export default Login;
