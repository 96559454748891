import React from 'react';
import { Box, Typography } from '@mui/material';

const Message = ({ message, username, self }) => {
    return (
        <Box
            display="flex"
            justifyContent={self ? 'flex-end' : 'flex-start'}
            padding="5px"
        >
            <Box
                padding="10px"
                bgcolor={self ? '#blue' : '#f1f1f1'}
                borderRadius="10px"
                maxWidth="60%"
            >
                {!self && (
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                    >
                        {username}
                    </Typography>
                )}
                <Typography variant="body1">{message}</Typography>
            </Box>
        </Box>
    );
};

export default Message;
