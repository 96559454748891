import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, Typography, Paper, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Message from './Message';

const Chat = ({ username }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [connectionStatus, setConnectionStatus] = useState('connecting'); // "connecting", "success", or "error"
    const ws = useRef(null);
    const navigate = useNavigate();
    const messagesEndRef = useRef(null); // 用于自动滚动到底部

    // 检查用户名是否存在
    useEffect(() => {
        const cachedUsername = localStorage.getItem('username');
        if (!username && !cachedUsername) {
            navigate('/'); // 跳转回登录页面
        }
    }, [username, navigate]);

    // 初始化聊天记录和用户名
    useEffect(() => {
        const cachedUsername = localStorage.getItem('username');
        const cachedMessages = localStorage.getItem('chatMessages');
        if (cachedUsername && !username) {
            localStorage.setItem('username', cachedUsername);
        }
        if (cachedMessages) {
            setMessages(JSON.parse(cachedMessages));
        }
    }, [username]);

    // 连接 WebSocket
    useEffect(() => {
        if (username) {
            localStorage.setItem('username', username); // 缓存用户名
            connectWebSocket();
            return () => {
                if (ws.current) ws.current.close();
            };
        }
    }, [username]);

    const connectWebSocket = () => {
        ws.current = new WebSocket('ws://45.77.131.69:3100'); // 修改端口为 3100

        ws.current.onopen = () => {
            setConnectionStatus('success');
            setTimeout(() => setConnectionStatus(null), 2000); // 2 秒后隐藏成功状态

            // WebSocket 连接成功后，调用后端 API 获取历史信息
            fetch('http://45.77.131.69:3100/messages') // 替换为你的后端接口
                .then((response) => response.json())
                .then((data) => {
                    setMessages(data);
                    localStorage.setItem('chatMessages', JSON.stringify(data)); // 更新缓存
                })
                .catch((err) => {
                    console.error('加载历史信息失败：', err);
                });
        };

        ws.current.onmessage = (event) => {
            const message = JSON.parse(event.data);
            setMessages((prev) => {
                const updatedMessages = [...prev, message];
                localStorage.setItem('chatMessages', JSON.stringify(updatedMessages)); // 更新缓存
                return updatedMessages;
            });
        };

        ws.current.onclose = () => {
            setConnectionStatus('error'); // 显示连接失败
        };

        ws.current.onerror = () => {
            setConnectionStatus('error'); // 显示连接失败
        };
    };

    const sendMessage = () => {
        if (newMessage.trim()) {
            const message = {
                username,
                message: newMessage.trim(),
            };

            // 本地先添加消息，立即缓存
            setMessages((prev) => {
                const updatedMessages = [...prev, { ...message, local: true }];
                localStorage.setItem('chatMessages', JSON.stringify(updatedMessages)); // 缓存消息
                return updatedMessages;
            });

            // 通过 WebSocket 发送消息
            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
                ws.current.send(JSON.stringify(message));
            }
            setNewMessage('');
            scrollToBottom(); // 发送消息后自动滚动到底部
        }
    };

    // 自动滚动到底部
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // 每次消息更新后滚动到底部
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100vh"
        >
            <Paper elevation={3} style={{ padding: '10px' }}>
                <Typography variant="h6">聊天室 - 用户名: {username || localStorage.getItem('username')}</Typography>
            </Paper>

            <Box
                flex={1}
                overflow="auto"
                padding="10px"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                bgcolor="#f9f9f9"
            >
                {messages.map((msg, index) => (
                    <Message
                        key={index}
                        message={msg.message}
                        username={msg.username}
                        self={msg.local || msg.username === username}
                    />
                ))}
                <div ref={messagesEndRef} /> {/* 用于滚动到底部 */}
            </Box>

            <Box display="flex" padding="10px" bgcolor="#fff">
                <TextField
                    fullWidth
                    variant="outlined"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault(); // 防止换行
                            sendMessage(); // 回车发送消息
                        }
                    }}
                    placeholder="输入消息并按回车发送..."
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={sendMessage}
                    style={{ marginLeft: '10px' }}
                >
                    发送
                </Button>
            </Box>

            {/* 连接状态提示 */}
            <Snackbar
                open={connectionStatus === 'connecting'}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="info" sx={{ width: '100%' }}>
                    正在连接... （可以嘗試刷新）
                </Alert>
            </Snackbar>

            <Snackbar
                open={connectionStatus === 'success'}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    连接成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={connectionStatus === 'error'}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    连接失败，显示缓存消息
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Chat;
