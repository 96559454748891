import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Chat from './components/Chat';

const App = () => {
    const [username, setUsername] = useState('');

    return (
        <Routes>
            <Route path="/" element={<Login setUsername={setUsername} />} />
            <Route path="/chat" element={<Chat username={username} />} />
        </Routes>
    );
};

export default App;
